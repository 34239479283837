import { TableBody, TableCell, TableRow } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { dashboardStore } from 'stores';

import { MathUtils } from 'shared/utils';

import './GestureTable.scss';

const cnGestureTable = block('GestureTable');

const GestureTable = () => {
  const GestureJOINTS = dashboardStore.GestureLoader.gestTable;
  const columns = dashboardStore.Stats;

  if (columns) {
 

  const firstData = columns[0];
  const secondData = columns[1];
  const thirdData = columns[2];

  return (
    <TableBody>
      {GestureJOINTS.map((joint, index) => {
        const boxPlotData = [
          {
            min: firstData?.min[index],
            max: firstData?.max[index],
            q1: firstData?.q1[index],
            q3: firstData?.q3[index],
            median: firstData?.median[index],
          },
          {
            min: secondData?.min[index],
            max: secondData?.max[index],
            q1: secondData?.q1[index],
            q3: secondData?.q3[index],
            median: secondData?.median[index],
          },
          {
            min: thirdData?.min[index],
            max: thirdData?.max[index],
            q1: thirdData?.q1[index],
            q3: thirdData?.q3[index],
            median: thirdData?.median[index],
          },
        ];
        const row = [firstData.median[index], secondData.median[index], thirdData?.median[index]];

        const selectRow = () => {
          if (dashboardStore.selectedGestureAdvanceSetting) {
            dashboardStore.setSelectedGesture(joint, boxPlotData[0]!, boxPlotData[1]!, boxPlotData[2]!);
          }
        };

        return (
          <>
            <TableRow
              data-tooltip={
                !dashboardStore.selectedGestureAdvanceSetting
                  ? 'Включите расширенные настройки'
                  : undefined
              }
              key={joint + index}
              onClick={selectRow}
              className={cnGestureTable('row', {
                selected: dashboardStore.selectedJoint?.name === joint,
              }).toString()}
            >
              <TableCell>{joint}</TableCell>
              {row.map((data: any) => {
                if (data == null) {
                  return <TableCell align="center">Null</TableCell>;
                } else {
                  return <TableCell align="center">{data}</TableCell>;
                }
              })}
            </TableRow>
          </>
        );
      })}
    </TableBody>
  );
}else {
  return (
    <TableBody>
      {GestureJOINTS.map((joint, index) => {
        const jointsValues =[
          dashboardStore.GestureLoader.data[joint],
          dashboardStore.GestureLoader2.data[joint],
          dashboardStore.GestureLoader3.data[joint]
        ]
        console.log(jointsValues)
        if (!jointsValues[0]) return null;
        if (!jointsValues[1] && !jointsValues[2]) jointsValues.length = 1
        
       const data = jointsValues.map((jointValues)=>{
          if (!!jointValues) {
            return MathUtils.getSummaryStats(jointValues.data)
          }
        });

        const selectRow = () => {
          dashboardStore.selectedGestureAdvanceSetting && dashboardStore.setSelectedGesture(joint, data[0]!,data[1]!,data[2]!);
        };

        return (
          <TableRow
            data-tooltip={(!dashboardStore.selectedGestureAdvanceSetting) ?"Включите расширенные настройки": undefined}
            key={joint + index}
            onClick={selectRow}
            className={cnGestureTable('row', {
              selected: dashboardStore.selectedJoint?.name === joint,
            }).toString()}
          >
            <TableCell>{joint}</TableCell>
            {data.map((data:any)=>{
               return  <TableCell align="center">{data?.median}</TableCell>
            })} 
          </TableRow>
        );
      })}
    </TableBody>
  );
}
};

export default observer(GestureTable);

import { makeAutoObservable, toJS } from 'mobx';

import { IJointInfoDto } from '../../interfaces';

export class DataGestureModel {
  public isLoading = false;

  public data: Record<string, IJointInfoDto> = {};

  public tableHeader: string[] = [];
  public gestTable: string[] = [];
  public tableData: number[][] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public async parseGestureData(file?: any) {
    if (file == undefined) return (this.data = {});
    
    try {
      this.isLoading = true;
      const headerKeys = file[0];
      const gestKeys = file.map((arr: any[]) => arr[0]);
      // gestKeys.shift();

      // file.map((arr: any[]) => {
      //   return  arr.shift()
      // });
      const getGestValue = (gest: number) => file.map((arr:any[])=> arr[gest])
      

      for(let i = 0; i < headerKeys.length; i++) {
        const headerKey = headerKeys[i];
        const values = getGestValue(i);
        console.log(values)
        // if (values[0] ==`Номер жеста`){
        //   values.shift()
        // }
        // values.shift();
        this.data[headerKey] = {
          data: [],
          mean: 0,
          std: 0,
          ucl: 0,
          lcl: 0,
          usl: 0,
          lsl: 0,
          min: 0,
          max: 0,
          ooc: [],
        };

        toJS(this.data[headerKey].data.push(values.sort()));
      }   
      this.tableHeader = gestKeys;
      this.gestTable = headerKeys;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }
}

import { makeAutoObservable } from 'mobx';
import {
  AmbientLight,
  Box3,
  Color,
  PerspectiveCamera,
  PointLight,
  Scene,
  Vector2,
  Vector3,
  WebGLRenderer,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';

import { SceneUtils } from 'shared';
import { ISceneStore } from 'shared/interfaces';
import { HandProcessingModel } from 'shared/models';

const isDebug = false;

const stats = new Stats();

isDebug && document.body.appendChild(stats.dom);

class SceneStore implements ISceneStore {
  public mainCanvas: HTMLCanvasElement;
  public context: WebGLRenderingContext;

  public controls: OrbitControls;

  public scene = new Scene();
  public muscle:any[] = [];
  public camera: PerspectiveCamera = new PerspectiveCamera();

  private readonly renderer: WebGLRenderer;
  private effectComposer: EffectComposer;
  public outlinePass: OutlinePass | null = null;

  public Musculs: any = [];

  public gradientMuscle: any = null;

  public handModel: HandProcessingModel | null = null;

  private lights: PointLight[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    const canvas = document.createElement('canvas');

    canvas.style.position = 'absolute';
    canvas.style.left = '0';
    canvas.style.top = '0';
    canvas.style.zIndex = '0';

    this.mainCanvas = canvas;
    this.context = this.mainCanvas.getContext('webgl2') || new WebGLRenderingContext();

    this.controls = new OrbitControls(this.camera, canvas);
    this.renderer = new WebGLRenderer({ canvas: this.mainCanvas, context: this.context });
    this.renderer.shadowMap.enabled = false;

    this.effectComposer = new EffectComposer(this.renderer);
  }

  public setMuscle(muscle:string){
    this.Musculs.push(muscle);
  }

  public setGradientMuscle(muscles:any){
    this.gradientMuscle = muscles;
  }

  public setActiveMuscle(muscle:string){
    this.muscle.push(muscle);
  }

  public setActiveMuscleNull(){
    this.muscle = [];
  }

  public zoomTo(bb: Box3, zoomFactor = 1) {
    const boxSize = bb.getSize(new Vector3());

    const maxDimension = Math.max(boxSize.x, boxSize.y, boxSize.z);

    this.camera.position.set(
      maxDimension * -zoomFactor,
      maxDimension * zoomFactor,
      maxDimension * zoomFactor,
    );
    this.camera.rotation.set(0, 0, 0);
    this.camera.lookAt(0, 0, 0);
  }

  public initModel() {
    console.log(this.handModel)
    /* if (this.handModel) return; */

    this.handModel = new HandProcessingModel(this);
    this.handModel.init(this.lights);
  }

  public initLight() {
    /* const spotLight = new SpotLight(0xffffff,0);
    this.scene.add(spotLight);

    spotLight.castShadow = false;

    spotLight.position.set(5,5,5);

    const sLightHelper = new SpotLightHelper(spotLight);
    this.scene.add(sLightHelper);
    const firstPointLight = new PointLight(0xfafafa,0);
    firstPointLight.position.set(5, 5, 5);

    this.scene.add(firstPointLight);
    this.lights.push(firstPointLight); */

    const ambidenLight = new AmbientLight(0xffffff);
    this.scene.add(ambidenLight)

    /* const secondPointLight = new PointLight().copy(firstPointLight);
    secondPointLight.position.set(1, 1, 1);

    this.scene.add(secondPointLight);
    this.lights.push(secondPointLight);

    const thirdPointLight = new PointLight().copy(firstPointLight);
    thirdPointLight.position.set(2, 2, 2);

    this.scene.add(thirdPointLight);
    this.lights.push(thirdPointLight); */

   /*  const spotLight2 = new SpotLight(0xffffff,100,0,Math.PI/2);
    this.scene.add(spotLight2);
    const sLightHelper2 = new SpotLightHelper(spotLight2);
    this.scene.add(sLightHelper2);


    spotLight2.position.set(-4,4,-1);

  
    const firstPointLight2 = new PointLight(0xfafafa, 300000);
    firstPointLight2.position.set(0, 8, 9);

    this.scene.add(firstPointLight2);
    this.lights.push(firstPointLight2);

    const secondPointLight2 = new PointLight().copy(firstPointLight2);
    secondPointLight2.position.set(-1, 11, -8);

    this.scene.add(secondPointLight2);
    this.lights.push(secondPointLight2);

    const thirdPointLight3 = new PointLight().copy(firstPointLight2);
    thirdPointLight3.position.set(1, 12,-2);

    this.scene.add(thirdPointLight3);
    this.lights.push(thirdPointLight3); */
  }

  private render() {
    requestAnimationFrame(this.render.bind(this));

    isDebug && stats.begin();

    this.controls.update();

    this.effectComposer.render();

    isDebug && stats.end();
  }

  public updateSceneSizes(width: number, height: number) {
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    // Update renderer
    this.renderer.setSize(width, height);
    this.effectComposer.setSize(width, height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  }

  public initScene(wrapper: HTMLDivElement) {
    wrapper.prepend(this.mainCanvas);

    this.scene.background = new Color(0.68, 0.68, 0.68);

    this.updateSceneSizes(wrapper.clientWidth, wrapper.clientHeight);

    this.camera.position.z = 1;
    this.controls.update();

    this.initLight();

    const renderPass = new RenderPass(this.scene, this.camera);
    this.effectComposer.addPass(renderPass);

    this.outlinePass = new OutlinePass(
      new Vector2(wrapper.clientWidth, wrapper.clientHeight),
      this.scene,
      this.camera,
    );
    this.outlinePass.visibleEdgeColor = new Color(0.7, 0.7, 0.7);
    this.outlinePass.hiddenEdgeColor = new Color(0.15, 0.15, 0.15);
    this.outlinePass.edgeStrength = 10;
    this.effectComposer.addPass(this.outlinePass);

    (window as any).scene = this.scene;

    this.render();
    console.log(this.scene)
  }

  public checkIntersection(mousePosition: Vector2,name?:string) {
    if (!this.outlinePass) return;

  
    SceneUtils.raycaster.setFromCamera(mousePosition, this.camera);
    const intersects = SceneUtils.raycaster.intersectObject(this.scene);

    this.handModel?.updateModelByIntersections(intersects,name);
  }

  public clearMucles() {
    this.handModel?.clearPrevSelectedObjectHighlight();
  }

  public deInit() {
    this.scene.clear();
    this.lights = [];
  }
}

export default new SceneStore();

import { useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { sceneStore } from 'stores';

import jsonData from './exampleResponse.json'

import './ScenePage2.scss';

const cnScenePage = block('ScenePage2');

const ScenePage2 = () => {
  const sceneWrapperRef = useRef<HTMLDivElement>(null);

  const checkIntersection = (e: PointerEvent) => {
    if (!sceneWrapperRef.current || !e.isPrimary) return;
  };

  const mocData = ['Локтевой_разгибатель_запястья001_2', 'Длинный_лучевой_разгибатель_зап_2'];

 /*  const structureValues: any = {};

  sceneStore.Musculs.forEach((structure: string) => {
    structureValues[structure] = Math.floor(Math.random() * 101);
  });

  // Выводим результат
  for (const [structure, value] of Object.entries(structureValues)) {
    console.log(`${structure}: ${value}`);
  } */



  console.log(jsonData);

  

  useEffect(() => {
    if (sceneWrapperRef.current) {
      sceneStore.setGradientMuscle(jsonData);
      

      /* if (!!sceneStore.gradientMuscle){ */
        sceneStore.initScene(sceneWrapperRef.current);
        sceneStore.initModel();
     /*  } */
      

      sceneWrapperRef.current.addEventListener('pointermove', checkIntersection);
    }

    return () => {
      sceneStore.deInit();

      if (sceneWrapperRef.current)
        sceneWrapperRef.current.removeEventListener('pointermove', checkIntersection);
    };
  }, [sceneWrapperRef.current]);

  useEffect(() => {
    const onResize = () => {
      if (sceneWrapperRef.current) {
        sceneStore.updateSceneSizes(
          sceneWrapperRef.current.clientWidth,
          sceneWrapperRef.current.clientHeight,
        );
      }
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <>
      <div className="dashboard">
        {sceneStore.Musculs?.map((el: any) => {
          return (
            <Button
              key={el}
              variant="outlined"
              onClick={() => sceneStore.handModel?.updateModelByBtns(el)}
            >
              {el}
            </Button>
          );
        })}
      </div>
      <div className={cnScenePage()} ref={sceneWrapperRef}>
        <div className="keyMscle">
          Выбраный элемент:
          <br />
          {sceneStore.muscle.map((el: any) => el + ', ')}
        </div>
        <Button
          variant="contained"
          className="clear"
          onClick={() => {
            sceneStore.clearMucles();
            sceneStore.setActiveMuscleNull();
          }}
        >
          удалить мыщцу из модели
        </Button>
        <Button
          variant="contained"
          className="clear"
          onClick={() => {
            mocData.map((muscle) => {
              sceneStore.handModel?.updateModelByBtns(muscle);
            });
          }}
        >
          Test
        </Button>
        <Button
          variant="contained"
          className="clear"
          onClick={() => {}}
        >
          Окрасить градиент
        </Button>
      </div>
    </>
  );
};

export default observer(ScenePage2);

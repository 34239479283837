import { useEffect, useRef, useState } from 'react';
import { Box, Grid } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  Rectangle,
  RectangleProps,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';

import { dashboardStore } from 'stores';

import { isLaptop } from '../../StatisticsComponent';
import { Lines } from '../JointLineChart/JointLineChart';

import PieChartWithNeedle from './PieChartWithNeedle/PieChartWithNeedle';

import './BoxPlotChart.scss';

type BoxPlot = {
  name: any;
  min: number;
  lowerQuartile: number;
  median: number;
  upperQuartile: number;
  max: number;
  average?: number;
};

// Used in stacked bar graph
type BoxPlotData = {
  name: any;
  min: number;
  bottomWhisker: number;
  bottomBox: number;
  topBox: number;
  topWhisker: number;
  average?: number;
  size: number; // for average dot size
};

const cnBoxPlotChart = block('BoxPlotChart');

const BoxPlotChart = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  let data: any = [];
  let dataBar: any[] = [];

  const selectedJoint = dashboardStore.selectedGesture!;

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
      setHeight(containerRef.current.offsetHeight);
    }
  }, [containerRef, selectedJoint]);
  const RadarArray = [];
  console.log(selectedJoint)
  const HorizonBar = (props: RectangleProps) => {
    const { x, y, width, height } = props;

    if (x == null || y == null || width == null || height == null) {
      return null;
    }

    return <line x1={x} y1={y} x2={x + width} y2={y} stroke={'lightblue'} strokeWidth={3} />;
  };

  const DotBar = (props: RectangleProps) => {
    const { x, y, width, height } = props;

    if (x == null || y == null || width == null || height == null) {
      return null;
    }

    return (
      <line
        x1={x + width / 2}
        y1={y + height}
        x2={x + width / 2}
        y2={y}
        stroke={'lightblue'}
        strokeWidth={5}
        strokeDasharray={'5'}
      />
    );
  };

  const useBoxPlot = (boxPlots: BoxPlot[]): BoxPlotData[] => {
    const data = boxPlots.map((v) => {
      if (v == undefined) {
        dashboardStore.selectedGesture = null;
      }
      return {
        name: v?.name,
        min: v?.min,
        bottomWhisker: v?.lowerQuartile - v?.min,
        bottomBox: v?.median - v?.lowerQuartile,
        topBox: v?.upperQuartile - v?.median,
        topWhisker: v?.max - v?.upperQuartile,
        average: v?.average,
        size: 100,
      };
    });

    return data;
  };

  if (dashboardStore.selectedGestureAdvanceSetting) {
    const getGenFun = (obj: { name?: any, min?: any; max: any; q1: any; median: any; q3: any },label: string | null) => {
      if (obj == undefined) return null;
      if ('min' in obj) {
        /* if (!!obj.min == false) return; */
        return {
          name: label,
          min: obj.min,
          max: obj.max,
          lowerQuartile: obj.q1,
          median: obj.median,
          upperQuartile: obj.q3,
          average: obj.median,
        };
      }
    };
    const emptyBoxPlot ={
      min: null,
      q1:null,
      q3:null,
      median:null,
      max:null,
    };


    // eslint-disable-next-line react-hooks/rules-of-hooks
    data = useBoxPlot([
      selectedJoint.jointData?.min != undefined ? getGenFun(selectedJoint?.jointData,'Первая дата')! : getGenFun(emptyBoxPlot,'Первая дата')!,
      selectedJoint.jointData2?.min != undefined ? getGenFun(selectedJoint?.jointData2,'Средняя дата')! : getGenFun(emptyBoxPlot,'Первая дата')!,
      selectedJoint.jointData3?.min != undefined ?getGenFun(selectedJoint?.jointData3,'Последняя дата')! : getGenFun(emptyBoxPlot,'Первая дата')!,
    ]);
  } else {
    data = selectedJoint.jointData;
    RadarArray.push([Lines.DATA_KEY, '#8884d8']);
    if (!!selectedJoint.jointData2) {
      RadarArray.push([Lines.DATA_KEY3, '#6a329f'], [Lines.DATA_KEY2, '#8884d8']);
    }
 dataBar = [
  {
    "name": data[0]?.subject,
    'Средняя дата': (data[0]?.Count - data[0].CountDate3) * -1,
    'Последняя дата': (data[0]?.Count - data[0].CountDate2) * -1,
  },
  {
    "name": data[1]?.subject,
     'Средняя дата': (data[1]?.Count - data[1].CountDate3) * -1,
    'Последняя дата': (data[1]?.Count - data[1].CountDate2) * -1,
  },
  {
    "name": data[2]?.subject,
    'Средняя дата': (data[2]?.Count - data[2].CountDate3) * -1,
    'Последняя дата': (data[2]?.Count - data[2].CountDate2) * -1,
  },
];
  }

  const [opacity, setOpacity] = useState(false);
  const [opacity2, setOpacity2] = useState(false);
  const [opacity3, setOpacity3] = useState(false);
  return (
    <>
      {dashboardStore.selectedGestureAdvanceSetting ? (
        <div className={cnBoxPlotChart()} ref={containerRef}>
          <ComposedChart
            barCategoryGap={100}
            barSize={width / 15}
            width={width}
            height={height}
            data={data}
          >
            <CartesianGrid strokeDasharray="5 5" />
            <Bar stackId={'a'} dataKey={'min'} fill={'none'} />
            <Bar stackId={'a'} dataKey={'bar'} shape={<HorizonBar />} />
            <Bar stackId={'a'} dataKey={'bottomWhisker'} shape={<DotBar />} />
            <Bar stackId={'a'} dataKey={'bottomBox'} fill={'#8884d8'} />
            <Bar stackId={'a'} dataKey={'bar'} shape={<HorizonBar />} />
            <Bar stackId={'a'} dataKey={'topBox'} fill={'#8884d8'} />
            <Bar stackId={'a'} dataKey={'topWhisker'} shape={<DotBar />} />
            <Bar stackId={'a'} dataKey={'bar'} shape={<HorizonBar />} />
            <ZAxis type="number" dataKey="size" range={[0, 100]} />

            <Scatter dataKey="average" fill={'red'} stroke={'#FFF'} />
            <XAxis dataKey={'name'} />
            <YAxis label={{ value: 'Качество жеста', angle: -90, position: 'insideLeft' }} />
          </ComposedChart>
        </div>
      ) : (
        <div className={cnBoxPlotChart()} ref={containerRef}>
          <Grid  container justifyContent="center" spacing={2}>
          <Grid  item sm={!isLaptop ? 8 : 12} lg={5}>
            <Box className={cnBoxPlotChart('item').toString()}>
              <ResponsiveContainer width="100%">
                <RadarChart cy="60%" outerRadius={'100%'}  data={data} >
                  <PolarGrid />
                  <PolarAngleAxis orientation="inner" stroke="#ffffff" dataKey="subject"/>

                  <PolarRadiusAxis angle={30}  domain={[0, 18]} orientation='left'/>

                  <Radar
                    name={Lines.DATA_KEY}
                    dataKey="Count"
                    stroke="#FFFFFF"
                    fill="#FFFFFF"
                    onMouseEnter={() => setOpacity(true)}
                    onMouseLeave={() => setOpacity(false)}
                    fillOpacity={!opacity ? 0.2 : 1}
                  >
                    <LabelList values="Pages of my website" offset={0} position="inside" angle={0} />
                    
                  </Radar>

                  <Radar
                    name={Lines.DATA_KEY3}
                    dataKey="CountDate3"
                    stroke="#ADFF2F"
                    fill="#ADFF2F"
                    onMouseEnter={() => setOpacity3(true)}
                    onMouseLeave={() => setOpacity3(false)}
                    fillOpacity={!opacity3 ? 0.5 : /* 0. */ 1}
                  >
                    <LabelList values="Pages of my website" offset={5} position="insideRight" angle={0} stroke="#FFFFFF"/>
                  </Radar>
                  <Radar
                    name={Lines.DATA_KEY2}
                    dataKey="CountDate2"
                    stroke="#FF69B4"
                    fill="#FF69B4"
                    onMouseEnter={() => setOpacity2(true)}
                    onMouseLeave={() => setOpacity2(false)}
                    fillOpacity={!opacity2 ? 0.6 : /* 0. */ 1}
                  >
                    <LabelList values="Pages of my website" offset={0} position="insideTop" angle={0}/>
                  </Radar>
                  <Legend />
                </RadarChart>
              </ResponsiveContainer>
            </Box>
            </Grid>
            <Grid  item sm={!isLaptop ? 8 : 12} lg={5}>
              <Box className={cnBoxPlotChart('item').toString()}>
                  <ResponsiveContainer width="95%">
                  <BarChart
                  
                    data={dataBar}
                    margin={{
                      top: 10,
                      right: 10,
                      left: 0,
                      bottom: 10,
                    }}
                  >
                    <CartesianGrid 
                      stroke='#fff'
                      horizontal={true}
                      vertical={false}
                     strokeDasharray="3 0" />
                    <XAxis 
                        stroke='#fff' 
                        dataKey="name"
                        tickSize={10}
                        axisLine={false}
                        padding={{left: 20}}
                        tick={({ payload, x, y, dy }) => (
                          <text
                            x={x}
                            y={y + 15}
                            dy={dy}
                            textAnchor="middle"
                            fontSize={14}
                          >
                            {payload.value}
                          </text>
                        )}
                         />
                    <YAxis 
                        stroke='#fff'
                        tickSize={5}
                        
                        />
                    <Tooltip cursor={{fill: "#FFFAFA", opacity: 0.6}}/>
                    <Legend />
                    <Bar
                      dataKey="Средняя дата"
                      fill="#82ca9d"
                      activeBar={<Rectangle fill="#2A834B" />}
                    />
                    <Bar
                      dataKey="Последняя дата"
                      fill="#8884d8"
                      activeBar={<Rectangle fill="#8984D8" />}
                    />
                  </BarChart>
                  </ResponsiveContainer>
              </Box>
            </Grid>
            <Grid item sm={!isLaptop ? 8 : 12} lg={5}>
            <Box className={cnBoxPlotChart('item1').toString()}>
                <ResponsiveContainer width="100%" >
                    <PieChartWithNeedle />
                </ResponsiveContainer>
            </Box>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default observer(BoxPlotChart);
/* eslint-disable prefer-const */
import { makeAutoObservable } from 'mobx';
import {
  /*  AmbientLight, */
  Box3,
  Color,
  Intersection,
  Mesh,
  MeshPhysicalMaterial,
  PointLight,
  Vector3,
  Vector4,
} from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { sceneStore } from 'stores';

import { HandModelMeshNames, MuscleHighlight } from 'shared/enums';
import { ISceneStore } from 'shared/interfaces';
import { MuscleMaterial } from 'shared/materials';
import { ColorUtils } from 'shared/utils';

/* import model from '@/assets/models/both.glb'; */
import modelPath from  "../../../assets/models/both.glb";

/* const modelUrl = '../../../assets/models/both.glb'; */

export class HandProcessingModel {
  private sceneStore: ISceneStore;

  private modelStructures: Record<string, Mesh> = {};

  constructor(sceneStore: ISceneStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.sceneStore = sceneStore;
  }

  public init(lights: PointLight[]) {
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    
    dracoLoader.setDecoderPath('/js/libs/draco-new/');
    loader.setDRACOLoader(dracoLoader);
    console.log(loader)

    loader.load(modelPath, (gltf) => {
      const modelData: any = gltf.scene;
      
      this.sceneStore.scene.add(modelData);
      const bb = new Box3();
      console.log(gltf)
      const lightsInfo = lights.map((light) => ({
        position: light.position.clone(),
        color: new Vector3(light.color.r, light.color.g, light.color.b),
        intensity: light.intensity,
      }));
      const accamulateResults: any = [];
      for (const child of modelData.children) {
        const collectIsMeshObjects = (obj: any): any[] => {
          let result: any = [];
          // Проверяем, есть ли свойство isMesh у текущего объекта
          if (obj.isMesh) {
            result.push(obj);
          }

          // Если есть свойство children и это массив, проходимся по каждому элементу
          if (Array.isArray(obj.children)) {
            for (let child of obj.children) {
              // Рекурсивно собираем объекты с isMesh из дочерних объектов
              result = result.concat(collectIsMeshObjects(child));
            }
          }

          return result;
        };

        for (const mesh of collectIsMeshObjects(child)) {
          bb.expandByObject(mesh);
          const currentMesh = mesh as Mesh;
          accamulateResults.push(mesh);
          if (currentMesh.name === HandModelMeshNames.Bones) {
            currentMesh.material = new MeshPhysicalMaterial({
              color: new Color(0.89, 0.85, 0.79),
              reflectivity: 0.5,
              roughness: 0.4,
            });

            continue;
          }
          sceneStore.setMuscle(mesh.name);

          if (mesh.name === sceneStore.muscle) {
            currentMesh.material = new MuscleMaterial(new Vector4(0, 0, 139, 1.0), lightsInfo);
            this.sceneStore.outlinePass!.selectedObjects = [currentMesh];
          } else {
            const randomColor = ColorUtils.getRandomColorExcludingBlue();
            const colorMesh = ColorUtils.generateGradient(sceneStore.gradientMuscle[mesh.name]);

            // Пример использования:
           // console.log(ColorUtils.generateGradient(1)); // rgb(0, 0, 255) — синий
            //console.log(ColorUtils.generateGradient(50)); // rgb(127, 0, 127) — фиолетовый
           // console.log(ColorUtils.generateGradient(100)); // rgb(255, 0, 0) — красный
           

           if(sceneStore.gradientMuscle[mesh.name]) {currentMesh.material = new MuscleMaterial(
              new Vector4(colorMesh[0], colorMesh[1], colorMesh[2], 1.0),
              lightsInfo,
            );} else{
              currentMesh.material = new MuscleMaterial(
                new Vector4(randomColor[0], randomColor[1], randomColor[2], 1.0),
                lightsInfo,
              );
            }
          }
          this.modelStructures[currentMesh.name] = currentMesh;
          /* mesh.traverse((node: { isMesh: any; geometry: any; material: any; }) => {
              if (node.isMesh) {
                console.log(node.geometry);
                console.log(node.material);
              }
            }); */
        }
      }
      this.sceneStore.zoomTo(bb); 
    });
  }

  updateModelByBtns(name: any) {
    sceneStore.setActiveMuscle(name);
    const selectedObject = this.sceneStore.handModel.modelStructures[name];
    this.sceneStore.outlinePass!.selectedObjects.push(selectedObject);
    const material = selectedObject.material as MuscleMaterial;
    if (selectedObject.name !== name) {
      this.clearPrevSelectedObjectHighlight();
    } else {
      material.updateHighlight(MuscleHighlight.HasHighlight);
    }
  }

  public clearPrevSelectedObjectHighlight() {
    if (this.sceneStore.outlinePass && this.sceneStore.outlinePass.selectedObjects.length) {
      const objects = this.sceneStore.outlinePass.selectedObjects;
      objects.map((object) => {
        const material = (object as Mesh).material as MuscleMaterial;

        if (object.name !== HandModelMeshNames.Skeleton)
          material.updateHighlight(MuscleHighlight.NoHighlight);
      });
    }
  }

  updateModelByIntersections(intersects: Intersection[], name?: string) {
    if (!this.sceneStore.outlinePass) return;

    if (intersects.length > 0) {
      this.clearPrevSelectedObjectHighlight();

      const selectedObject = intersects[0].object as Mesh;

      const material = selectedObject.material as MuscleMaterial;
      if (selectedObject.name !== HandModelMeshNames.Skeleton) {
        if (selectedObject.name !== name) {
          this.clearPrevSelectedObjectHighlight();
        } else {
          material.updateHighlight(MuscleHighlight.HasHighlight);

          this.sceneStore.outlinePass.selectedObjects = [selectedObject];
        }
      }
    } else {
      this.clearPrevSelectedObjectHighlight();

      this.sceneStore.outlinePass.selectedObjects = [];
    }
  }
}

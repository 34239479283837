import { Key,useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { observer } from 'mobx-react';

import { patientsStore } from 'stores';

import MainPageHeader from '../MainPage/components/MainPageHeader';

import UserTable from './components/UserTable';

import './DoctorPersonalPage.scss';

export interface User {
  id: string | number;
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  option?: string;
}

const DoctorPersonalPage: React.FC = () => {
  const [firstArray, setFirstArray] = useState<any>([]);
  const [secondArray, setSecondArray] = useState<User[]>(
    JSON.parse(localStorage.getItem('secondArray') || '[]'),
  );
  const [selectedOption, setSelectedOption] = useState('');

 
  const filterPatients = (user: any) =>
    setFirstArray(
      firstArray.filter((u: any) => {
        return u !== user;
      }),
    );

  useEffect(() => {
    const fetchData = async () => {
      patientsStore.getProfile()
      patientsStore.getDoctors();
      await patientsStore.getPatientsOnly();
      console.log(patientsStore.client)
      if (!!secondArray.length) {
        const arr = patientsStore.patientsOnly.filter((user: any) =>
          secondArray.find((user2: any) => user.id == user2.id),
        );
        const set = new Set(arr);
        setFirstArray(patientsStore.patientsOnly.filter((e) => !set.has(e)));
      } else {
        setFirstArray(patientsStore.patientsOnly);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    localStorage.setItem('secondArray', JSON.stringify(secondArray));
  }, [secondArray]);

  const handleAddToTable = (user: User) => {
    if (selectedOption !== '') {
      const userExists = secondArray.some(
        (u) => u.first_name === user.first_name && u.username === user.username,
      );
      if (!userExists) {
        const selectedUser = { ...user, option: selectedOption };
        setSecondArray([...secondArray, selectedUser]);
        {
          return filterPatients(user);
        }
      }
    }
  };
  const handleDeleteFromTable = (userToDelete: User) => {
    event?.preventDefault();
    setSecondArray(secondArray.filter((user) => user !== userToDelete));
    firstArray.unshift(userToDelete);
  };

  return (
    <div className="background-container">
      <Box>
        <MainPageHeader />
      </Box>

      <div className="select_container">
        <FormControl sx={{ minWidth: 320}}>
          <InputLabel id="demo-simple-select-label">Выберите ответственного врача</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedOption}
            label="Выберите ответственного врача"
            onChange={(e) => setSelectedOption(e.target.value)}
          >
            {patientsStore.doctors.map((doctor: any) => (
              <MenuItem value={doctor.last_name+' '+doctor.first_name}>
                {doctor.last_name} {doctor.first_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="select_button-container">
          {firstArray.map((user: any, index: Key | null | undefined) => (
            <div key={index}>
              <button className="select_button-user" onClick={() => handleAddToTable(user)}>
                {user.username} {user.first_name} {user.last_name}
              </button>
            </div>
          ))}
        </div>
      </div>
      <UserTable
        users={secondArray}
        doctors={patientsStore.doctors}
        setUserClear={setSecondArray}
        onDelete={handleDeleteFromTable}
      />
    </div>
  );
};

export default observer(DoctorPersonalPage);

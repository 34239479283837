import { useEffect } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { dashboardStore, sceneStore } from 'stores';

import { ERROR_COLOR, SUCCESS_COLOR, WARNING_COLOR } from 'shared/constants';

import './PowerTable.scss';

const GRADIENT_STEPS = 20;

const getGradientStrSteps = (percent: number,percent2:number,percent3:number) => {
  const percentCount = (percent2 != undefined && percent3 !=undefined) ? 3 : (percent2 != undefined || percent3 !=undefined) ? 2 : 1
  const stepsCount = parseFloat((GRADIENT_STEPS * (((percent+(percent2 ?? 0)+(percent3 ?? 0))/percentCount) / 100)).toFixed(0));
 
  return new Array(stepsCount).fill(null);
};

const getGradientColor = (currentStep: number) => {
  return currentStep < GRADIENT_STEPS * 0.4
    ? ERROR_COLOR
    : currentStep < GRADIENT_STEPS * 0.7
    ? WARNING_COLOR
    : SUCCESS_COLOR;
};

const cnPowerTable = block('PowerTable');

const PowerTable = () => {
  const powerJOINTS = dashboardStore.PowerLoader.tableHeader;
  const zxc:any = [];
  console.log(powerJOINTS)
useEffect(()=>{
  const index=zxc.indexOf(Math.max(...zxc));
  sceneStore.setMuscle(powerJOINTS[index])
  
},[zxc])
  return (
    <TableBody>
      {powerJOINTS.map((joint, index) => {
        const jointsValues =[
          dashboardStore.PowerLoader.data[joint],
          dashboardStore.Power3Loader.data[joint],
          dashboardStore.Power2Loader.data[joint]
        ]
        
        if (!jointsValues[0]) return null;
        if (!jointsValues[1] && !jointsValues[2]) jointsValues.length = 1

        const gradientSteps = getGradientStrSteps(jointsValues[0]?.data[0],jointsValues[1]?.data[0],jointsValues[2]?.data[0]);
        zxc.push(gradientSteps.length)
        const percents = jointsValues.map((jointValues)=>{
          if (!!jointValues) {return jointValues.data[0] + ' Дж';}else{return 'нет данных'}
        })

        const selectRow = () => {
          dashboardStore.setSelectedJoint(joint, jointsValues[0],jointsValues[1],jointsValues[2]);
        };

        return (
          <TableRow
            key={joint + index}
            onClick={()=>selectRow()}
            className={cnPowerTable('row', {
              selected: dashboardStore.selectedJoint?.name === joint,
            }).toString()}
          >
            <TableCell>{joint}</TableCell>
            {percents.map((percent:any)=>{
              return <TableCell align="center">{percent}</TableCell>
            })}
            <TableCell align="center">
              <div
                className={cnPowerTable('gradient')}
                style={{ width: `${GRADIENT_STEPS * 6}px` }}
              >
                {gradientSteps.map((_, index) => {
                  return (
                    <div
                      key={index}
                      className={cnPowerTable('gradientStep')}
                      style={{ backgroundColor: getGradientColor(index + 1) }}
                    ></div>
                  );
                })}
              </div>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default observer(PowerTable);
